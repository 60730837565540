import React from 'react';
import Layout from '../components/layoutEN';
import SEO from '../components/seo';
import About from '../containers/videoEN';

type AboutPageProps = {};

const AboutPage: React.FunctionComponent<AboutPageProps> = () => {
  return (
    <Layout>
      <SEO
        title="Sifrein artist videos"
        description="Some videos of artists from the Sifrein Paris gallery"
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
